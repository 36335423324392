.select {
  width: 8rem;
  position: relative;
  box-sizing: border-box;
}

.select-button {
  width: 100%;
  background: none;
  border: 1px solid #414141;
  border-radius: 2px;
  cursor: pointer;
  text-align: start;
}

.select--dropdown {
  position: absolute;
}

.select--dropdown-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #dbdbdb;
  border-top: none;
  width: 8rem;
  box-sizing: border-box;
}

.select--dropdown-button {
  width: 100%;
  background: none;
  cursor: pointer;
  border: none;
  text-align: start;
}

.select---dropdown-list-item:not(:last-child) .select--dropdown-button {
  border-bottom: 1px solid #dbdbdb;
}
