.tab-nav {
  list-style: none;
  display: flex;
  padding: 0 0 0.25rem 0;
  margin: 0;
  gap: 0.5rem;
  border-bottom: 1px solid #dbdbdb;
}

.tab-button {
  background: inherit;
  border: 1px solid #414141;
  border-radius: 0.25rem;
  cursor: pointer;
}